/**
 * Mobile Terms
 */
import styled from '@emotion/styled'
import React from 'react'
import {
  Body,
  TitleLarge,
  TitleMedium,
  UnorderedList,
  UnorderedListItem,
} from '../components/RichText'
import { mq } from '../hooks'
import { Layout } from '../templates'

export default () => (
  <Layout>
    <>
      <div css={mq({ margin: ['1rem 1rem', '1rem 6rem'] })}>
        <TitleLarge textStyle={{ fontWeight: 'bold' }}>Accolade Digital Tools Terms of Use</TitleLarge>
        <Body textStyle={{ color: '#003DA6' }}>Last updated: February 16, 2021</Body>
        <Body textStyle={{ color: '#003DA6' }}>
          These “<strong>Terms</strong>” govern your use of one of more digital tools made available by Accolade, including the Accolade Mobile application, Accolade Portal (including MyAccolade), and/or any other domains associated with the digital tools (the “
          <strong>Accolade Digital Tools</strong>”), the images and content provided via the digital tools, and/or any other Accolade owned websites which are linked from the Digital Tools. These Terms are intended to be read in conjunction with the Privacy Notice for the Accolade Digital Tools. By using the Accolade Digital Tools, you are expressly agreeing to be bound by these Terms. If you do not agree to these Terms, please discontinue your use of Accolade Digital Tools. {' '}
          <strong>Please read these Terms carefully.</strong>
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>REVISIONS TO THESE TERMS</TitleMedium>
        <Body>
          These Terms were last updated as of the date indicated above. Accolade may revise these Terms at any time. Should these Terms change materially, Accolade will indicate that a change has been made on your next use of the Accolade Digital Tools. Any revision and/or addition to these Terms shall become effective and binding on you when you continue to use the Accolade Digital Tools on or after posting of such revision and/or addition. We will never make changes to these Terms that diminish the protection of your personal information or are inconsistent with applicable law.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>NOT INTENDED FOR USERS UNDER AGE 18</TitleMedium>
        <Body>
          The Accolade Digital Tools is not intended for use by anyone under the age of 18, and does not seek to obtain information from any person under the age of 18. By using the Accolade Digital Tools, you are attesting to the fact that you are at least 18 years of age, or older and possess the legal rights and ability to agree to these Terms.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>NO MEDICAL OR LEGAL ADVICE; EMERGENCIES</TitleMedium>
        <Body>
          Accolade is here to simplify healthcare and your health benefits for you and your family. We are here to help you understand your healthcare options, to facilitate your use of the healthcare system and to support you as you make your healthcare decisions. The content we provide you is for informational purposes only. No material contained within or provided through the Accolade Digital Tools should be construed as medical advice and/or treatment. Accolade does not practice medicine and no physician or nurse to patient relationship is created as a result of your use of the Accolade Digital Tools. You should seek the care of your healthcare professional.
        </Body>
        <Body>
          Tools designed to assist you in searching for and evaluating doctors and other healthcare providers utilize third-party data sources, which may contain data that is outdated or inaccurate. You are responsible for confirming the accuracy of information you may receive from such tools directly with your provider, including, without limitation, whether the provider is in-network, whether certain treatments or procedures are covered by your health insurance, and the total and out-of-pocket costs of a visit, treatment or procedure. Do not assume that out-of-pocket maximums apply to your particular visit with a provider or that other additional or balanced billed costs may additionally apply. Accolade does not guarantee that any particular provider will be perfectly suited for you or that you will achieve any particular health outcomes, and Accolade disclaims any liability from any misdiagnosis, mistreatment, negligence or malpractice you may experience in choosing to see any doctor or other healthcare provider.
        </Body>
        <Body>
          IF YOU ARE EXPERIENCING A HEALTH EMERGENCY, CALL 911 OR YOUR HEALTHCARE PROFESSIONAL IMMEDIATELY.
        </Body>
        <Body>
          IF AN ACCOLADE REPRESENTATIVE REASONABLY SUSPECTS THAT YOU MAY BE EXPERIENCING A HEALTH EMERGENCY, OR MAY BE A DANGER TO YOURSELF OR ANOTHER PERSON, ACCOLADE RESERVES THE RIGHT, AND YOU EXPRESSLY AGREE, THAT ACCOLADE MAY CONTACT THE APPROPRIATE EMERGENCY SERVICES, INCLUDING CALLING 911, CONTACTING CHILD PROTECTION SERVICES, A SUICIDE HOTLINE OR OTHER APPROPRIATE SERVICE.
        </Body>
        <Body>
          Nothing contained or provided through the Accolade Digital Tools is intended or should be construed as legal advice or guidance. No attorney-client relationship is created between you and Accolade or its personnel. If you have any questions about any law, rule or regulation, or seek legal advice regarding your healthcare, you should contact your own legal counsel.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>DESCRIPTION OF THE ACCOLADE DIGITAL TOOLS</TitleMedium>
        <Body>
          We are delighted to bring you the Accolade Digital Tools, which permits you and your families the opportunity to receive the same great Accolade service that we provide to hundreds and thousands of families through our Accolade Health Assistants® in the form of a mobile app. The Accolade Digital Tools will allow you to communicate with your personal Accolade Health Assistant® and to access certain information about your health benefits and claims. All such communications are strictly limited to and for the purpose of providing information to you about the benefits available to you under your health plan, including the services provided by Accolade.
        </Body>
        <Body>
          In order to provide you with access to the Accolade Digital Tools, there is certain personal and protected health information that the Accolade Digital Tools will securely access and transmit. Much of this information is similar to the information that our Accolade Health Assistant® and clinicians access for you today, and includes:
        </Body>
        <UnorderedList textStyle={{ marginLeft: '15px' }}>
          <UnorderedListItem>
            general personal and demographic information (e.g., name, sex, date of birth, address, dependents)
          </UnorderedListItem>
          <UnorderedListItem>
            health plan benefits and eligibility information
          </UnorderedListItem>
          <UnorderedListItem>health and claims information</UnorderedListItem>
          <UnorderedListItem>
            other personal or protected health information that you may provide to Accolade via the Accolade Digital Tools
          </UnorderedListItem>
        </UnorderedList>
        <Body>
          We access and use this information only as minimally necessary to provide Accolade Digital Tools to you. Even if you decide not to use the Accolade Digital Tools, Accolade will have access to certain of the above-referenced information in its role as a service provider to your employer and/or a health plan carrier through which you have purchased a health plan or other benefits. As an employee or member of a health plan of an Accolade customer, you have already consented to Accolade’s access to and use of information for these purposes. This will not change whether or not you use the Accolade Digital Tools. Even so, we only use or disclose your information as permitted by HIPAA (see the Privacy Notice) and to provide or inform you about Accolade Services or other services or benefits available to you under your health plan.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>OTHER AGREEMENTS</TitleMedium>
        <Body>
          These Terms apply to Accolade Digital Tools generally and are subject to any other agreement between you and Accolade and such terms of use, legal notices or disclaimers applicable to specific products or services made available to you by Accolade.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>REGISTERING TO USE THE ACCOLADE DIGITAL TOOLS</TitleMedium>
        <Body>
          Given the nature of the sensitive information available through the Accolade Digital Tools, Accolade has created certain security procedures, including the use of user ids, passwords, and in some cases biometric security means to assist you in keeping your information private and confidential. By registering to use the Accolade Digital Tools, you agree to provide Accolade with true, accurate and complete information about yourself and to maintain and promptly update your information as may be necessary to keep it accurate and up to date. We reserve the right to terminate your access to the Accolade Digital Tools at any time for any or no reason.
        </Body>
        <Body>
          In order to verify your identity when registering to use the Accolade Digital Tools, we will ask you to provide certain personal information, which may include your name, date of birth, home address, employee ID, social security number or other information as may be determined from time to time by Accolade to be required for your protection. If you are unwilling to provide this information, you will not be permitted to register for use of the Accolade Digital Tools.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>
          YOUR RESPONSIBILITY FOR SECURITY OF YOUR ACCESS INFORMATION
        </TitleMedium>
        <Body>
          You are responsible for securing your personal mobile device and your access information to prevent unauthorized third persons from accessing your information via the Accolade Digital Tools. You will be responsible for all activities taken during use of the Accolade Digital Tools using your access information. You must exit from the Accolade Digital Tools after each use to ensure no information is available after you end a session on the Accolade Digital Tools. Accolade will not be liable for any losses or damages arising from your failure to comply with these Terms.
        </Body>
        <Body>
          If you believe that your access information has been compromised or become aware of any other security incident involving the Accolade Digital Tools or your account, you agree to immediately notify Accolade.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>NO MISREPRESENTATION OF YOUR IDENTITY</TitleMedium>
        <Body>
          You will not falsely state or otherwise misrepresent your identity or affiliation with another person or entity to obtain access to the Accolade Digital Tools or to access another person’s information.
          Except where expressly authorized by law, e.g., in the case where you are an authorized personal representative for another person, you will not use another person’s access information to access the Accolade Digital Tools. Violation of this provision may subject you to civil and criminal penalties.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>USING THE ACCOLADE DIGITAL TOOLS</TitleMedium>
        <Body>
          Please consider Accolade’s provision of the Accolade Digital Tools to you a privilege and not a right. Accolade grants you a limited, non-exclusive, non-transferable, revocable license to use the Accolade Mobile solely for your own personal use and solely in accordance with these Terms.
          Accolade may revoke or suspend your access at any time for any reason.
        </Body>
        <Body>You are prohibited from:</Body>
        <UnorderedList textStyle={{ marginLeft: '15px' }}>
          <UnorderedListItem>
            using the Accolade Digital Tools in any manner that could damage or overburden any Accolade server, or any network connected to any Accolade server, as all servers have limited capacity and are used by many people;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Accolade Digital Tools in any manner that would interfere with another party's use of the Accolade Digital Tools;
          </UnorderedListItem>
          <UnorderedListItem>
            including the term ACCOLADE®, ACCOLADE HEALTH ASSISTANT®, or any Accolade trademark or executive's name or any variation of the foregoing in any unauthorized manner or as a meta-tag or hidden textual element in another web site or application;
          </UnorderedListItem>
          <UnorderedListItem>
            using any robot, spider, intelligent agent, other automatic device or manual process to search, monitor or copy information provided via the Accolade Digital Tools or the reports, data, information, content, software, products, services or other materials on, generated by or obtained from the Accolade Digital Tools, whether through links or otherwise (collectively "Devices") without Accolade's permission, provided that generally available third party web browsers may be used without such permission;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Accolade Digital Tools or the Devices in any manner that could create an impression of affiliation, sponsorship or endorsement by Accolade or any of its customers;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Accolade Digital Tools for any unlawful, illegal or prohibited purpose;
          </UnorderedListItem>
          <UnorderedListItem>
            using the Accolade Digital Tools to damage or attempt to do harm to others; or
          </UnorderedListItem>
          <UnorderedListItem>
            uploading or posting statements or information that would be reasonably understood to be offensive, obscene, constitute pornography, illegal content, or any of the like.
          </UnorderedListItem>
        </UnorderedList>
        <Body>
          If you do any harm by your wrongful actions in using the Accolade Digital Tools, you agree to pay Accolade or any other party associated with Accolade for any losses, damages or costs, resulting from your failure to comply with these Terms.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>MINIMUM SYSTEM REQUIREMENTS</TitleMedium>
        <Body>
          The Accolade Digital Tools is designed to work on a variety of mobile devices, including devices running iOS and Android operating systems. The Accolade Digital Tools may not be compatible with all devices or operating systems, especially older operating systems that have not been regularly updated. Accolade shall have no responsibility or liability for any non-compatibility, loss of functionality or degraded performance in the Accolade Digital Tools due to any particular device or operating system. You are responsible for keeping for your device and operating system up to date.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>
          COMMUNICATING WITH YOUR ACCOLADE HEALTH ASSISTANT® OR ACCOLADE
          CLINICIAN
        </TitleMedium>
        <Body>
          The Accolade Digital Tools contains functionality for you to create an asynchronous messaging session (e.g., chat) with your Accolade representative, which may include an Accolade Health Assistant® or Accolade clinician. We try to connect you with your personal Accolade representative, but cannot guarantee that your personal Accolade representative will always be available at the times you attempt to contact them. If your personal Accolade representative is not available, you will have the option to leave a message for them to get back to you, or connect with another Accolade representative.
        </Body>
        <Body>
          Be courteous. Your Accolade representative is there to help you. However, Accolade follows strict anti-harassment and anti-discrimination policies and will not tolerate inappropriate behavior. Your access will be immediately terminated, in our sole and absolute discretion, if you harass, discriminate, threaten, or otherwise engage in any inappropriate behavior during any communication with your Accolade representative.  Subject to applicable law, such behavior may be reported to your employer and you expressly agree to such disclosure.
        </Body>
        <Body>
          Accolade’s typical hours of operation are between 8 a.m. through 8 p.m. eastern time. If your employer is based on the west coast of the United States, Accolade’s hours of operation may be extended to 8 p.m. pacific time. Your Accolade representative may not be available at all times.
        </Body>
        <Body>
          Accolade prides itself on providing accurate, up-to-date information to you in helping you navigate your healthcare. However, the content and information contained on and provided through the Accolade Digital Tools may contain inaccuracies and typographical errors, and is provided on an “as is” basis. Accolade makes no representations about suitability, accuracy, completeness, reliability or timeliness of information, content or services. If you are dissatisfied with information provided on the Accolade Digital Tools, please advise Accolade immediately. Accolade will not be responsible or liable for any direct, indirect, equitable, consequential, special or monetary damages resulting from your use of the Accolade Digital Tools. The use of this information is at your own risk.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>THIRD PARTY INFORMATION</TitleMedium>
        <Body>
          Whether via your Accolade representative or through the Accolade Digital Tools, Accolade may provide links to other third-party websites that are not under our control. Accolade is not responsible for the content found on any third-party sites. We provide these links for your convenience and for information purposes only.
        </Body>
        <Body>
          Following links to or from any other sites or pages shall be at your own risk and Accolade accepts no responsibility or liability, to the extent permitted by any law, for any losses or penalties whatsoever that may be incurred as a result of any linking to any location on any linked sites or pages.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>USE OF INFORMATION YOU PROVIDE</TitleMedium>
        <Body>
          Accolade complies with all laws and regulations which apply to our collection, retention and use of information pertaining to you, and we shall take all practicable steps to ensure that any information collected from you is protected against unauthorized or accidental access, erasure or use. More specifically, all of your personal information and any PHI you provide will be kept private and secure and treated in accordance with the HIPAA Privacy and Security Rules. With respect to your personal information, Accolade has adopted privacy and security measures that comply with, and in many cases exceed, the standards set forth by the HIPAA Privacy and Security Rules.
        </Body>
        <Body>
          You may post information about you and your family members, including minor children for whom you are the legal guardian or personal representative. You are solely responsible for the information that you submit. You agree that Accolade may access and use this information to provide services to you and your family. All information or content provided by you to Accolade will be used by Accolade in accordance with our {' '}
          <a style={{ textDecoration: 'underline', color: '#48D597', cursor: 'pointer' }} href="https://accolade.com/digital-privacy-policy">Privacy Notice</a> . Specifically, know that our communications with you are strictly limited to and for the purpose of providing information to you about the benefits available to you under your health plan, including the services provided by Accolade.
        </Body>
        <Body>
          Accolade may monitor your usage of the Accolade Digital Tools in order to improve quality, and to enhance our service to you.
        </Body>
        <Body>
          In addition, certain information that Accolade will collect and use via the Accolade Digital Tools may constitute Protected Health Information (PHI) under HIPAA. Your employer and Accolade have contracted with various health-related and insurance-related service providers. Some of these service providers provide health-related services that may be of interest to you, such as tele medicine, second opinion services, cost transparency, or services that can assist you with specific conditions, such as diabetes or obesity. We will only contact you about such services if they are part of your health plan. We will not contact you about third party services that are not part of your health plan, or permit such third parties to independently market to you.
        </Body>
        <Body>
          While Accolade does not sell your information to any third parties for remuneration, and complies with applicable law regarding the disclosure of PHI, in order to provide you with the full benefit of
          Accolade services and the services that your employer or health plan may offer to you, Accolade may provide certain information, including PHI, to such service providers in order to assist you in using such services. Disclosure may also be made as permitted by applicable law for the purpose of treatment, payment or health care operations as permitted by HIPAA. You expressly authorize Accolade to make such disclosures. Again, rest assured that Accolade makes such disclosures as permitted by HIPAA and in connection with Accolade Services and the specific benefits available to you under your health plan. We will not provide any information to third parties to enable them to independently market their services to you, which are not be part of your health plan benefits.
        </Body>
        <Body>
          Accolade, your employer, and your health plan and such third party service providers may want to provide you with relevant products and service offerings that are part of your health plan benefits, from time to time, and you expressly authorize Accolade and such entities to use your information to contact you about these products and services.  You acknowledge that you are not required to use or purchase any of these products or services.
        </Body>
        <Body>
          However, if you do choose to purchase or use such products or services, you may be asked by such service provider to provide additional personal information in order to receive such products or services. Accolade is not responsible for any information that you choose to provide to such service providers, and you understand that such service providers may not be subject to health information privacy laws. Accolade will not disclose any such information to service providers that are not be subject to health information privacy laws unless they abide by specific safeguards and contractual restrictions that protect your privacy.
        </Body>
        <Body>
          Your authorization hereunder is voluntary. We will not, and do not have the ability to condition your enrollment in any health plan or eligibility for benefits or payment of claims in a health plan on your accepting such authorization.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>SMS TEXT MESSAGING</TitleMedium>
        <Body>
          The Accolade service enables communication by means of SMS text messages to and from your mobile device, if you elect to receive SMS messages.
        </Body>
        <Body>
          You may text TNC to 67793 to receive a link to download the mobile app or ask your Accolade Health Assistant to have a link sent to you via SMS. To stop the SMS text service at any time, text STOP to 67793. To receive help information, text HELP to 67793 or reply HELP to any message or email: member.support@accolade.com.
        </Body>
        <Body>
          Carriers are not liable for delayed or undelivered messages.
        </Body>
        <Body>
          Consent to receive SMS text messages is not required as a condition of registering for or using the Accolade service. Texts may be sent using an automatic telephone dialing system. Message and data rates may apply. You will receive one message per request. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>INTELLECTUAL PROPERTY</TitleMedium>
        <Body>
          All of the information and content displayed and used on the Accolade Digital Tools – photographs, articles, text, guides, images, designs, video and audio clips, as well as Accolade’s trademarks, logos, domain names, and any and all copyrightable materials and any other material relating to the “look and feel” of the Accolade Digital Tools – is owned by or licensed to Accolade and is protected as intellectual property or otherwise. Subject to your strict compliance with these Terms of Use, Accolade grants you a limited, personal, non-exclusive, non-commercial, revocable, non-assignable and non-transferable license to access, download, view, use the content and information on the Accolade Digital Tools. You must retain all copyright, trademark and other proprietary notices contained on the Accolade Digital Tools. You have no other rights or interests, and you will not permit any improper or unauthorized third-party use or access.
        </Body>
        <Body>
          UNAUTHORIZED USE, COPYING, REPRODUCING, STORING, MODIFYING, REPUBLISHING, UPLOADING, DOWNLOADING, POSTING, TRANSMITTING, DISTRIBUTING, DUPLICATING, REMOVING OR ALTERING OR ANY OTHER MISUSE OF ANY OF THE CONTENT IS STRICTLY PROHIBITED. You
          agree to abide by any and all additional copyright notices, information or restrictions contained in any part of the Accolade Digital Tools.
        </Body>
        <Body>
          If you believe that any content on the Accolade Digital Tools contains content that violate your rights or has been copied in a way that constitutes a copyright infringement, please let Accolade know immediately at the address listed below.
        </Body>
        <Body>
          Accolade reserves the right to suspend or terminate your access to the Accolade Digital Tools if we believe that the information you submit infringes another’s rights or otherwise violates any law, rule or regulation.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>DISCLAIMERS/LIMITATIONS ON LIABILITY</TitleMedium>
        <Body>
          THE ACCOLADE DIGITAL TOOLS, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, SOFTWARE AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH OR SENT FROM THE ACCOLADE DIGITAL TOOLS, IS PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS”. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, ACCOLADE MAKES NO REPRESENTATIONS OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOVER, EITHER EXPRESS OR IMPLIED, ABOUT: (A) THE ACCOLADE DIGITAL TOOLS; (B) THE CONTENT AND SOFTWARE ON AND PROVIDED THROUGH THE ACCOLADE DIGITAL TOOLS; (C) THE FUNCTIONS MADE ACCESSIBLE ON OR ACCESSED THROUGH THE ACCOLADE DIGITAL TOOLS; (D) THE MESSAGES AND INFORMATION SENT FROM THE ACCOLADE DIGITAL TOOLS BY USERS; (E) ANY INFORMATION, PRODUCTS OR SERVICES OFFERED VIA THE ACCOLADE DIGITAL TOOLS OR HYPERTEXT LINKS TO THIRD PARTIES; AND/OR (F) SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE ACCOLADE DIGITAL TOOLS OR ANY LINKED SITE. ACCOLADE DOES NOT WARRANT THAT THE ACCOLADE DIGITAL TOOLS, ANY OF THE ACCOLADE DIGITAL TOOLS’S FUNCTIONS OR ANY CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL
          BE CORRECTED; OR THAT THE ACCOLADE DIGITAL TOOLS OR THE SERVERS THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </Body>
        <Body>
          ACCOLADE SHALL NOT BE LIABLE FOR THE USE OF THE ACCOLADE DIGITAL TOOLS INCLUDING, WITHOUT LIMITATION, THE CONTENT AND ANY ERRORS CONTAINED THEREIN.
        </Body>
        <Body>
          YOU UNDERSTAND AND AGREE THAT ACCOLADE LIMITS ITS LIABILITY IN CONNECTION WITH YOUR USE OF THE ACCOLADE DIGITAL TOOLS AND SHALL NOT BE LIABLE FOR ANY DAMAGES OR LOSS OF ANY KIND. IF YOU ARE DISSATISFIED WITH THE ACCOLADE DIGITAL TOOLS OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE ACCOLADE DIGITAL TOOLS OR THE CONTENT.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>LOCAL LAWS AND REGULATIONS</TitleMedium>
        <Body>
          Accolade services, including the Accolade Digital Tools, are generally available only within the United States and are not directed at persons who may download or access the Accolade Digital Tools from locations outside the United States.
        </Body>
        <Body>
          Accolade does not represent that any of the materials, opinions or other content on the Accolade Digital Tools are appropriate for use or permitted by local laws in all jurisdictions. Those who access the Accolade Digital Tools do so on their own initiative, and are responsible for compliance with applicable local laws or regulations; legal advice should be sought in cases of doubt. If you are prohibited from accessing websites, such as the Accolade Digital Tools, from your location, you should not access the Accolade Digital Tools, and Accolade take no responsibility for such prohibited access.
        </Body>
        <TitleMedium textStyle={{ color: '#003DA6' }}>CONTACT US</TitleMedium>
        <Body>
          If you have any questions, comments or concerns about the Accolade Digital Tools or these Terms, please contact us as follows:
        </Body>
        <table
          style={{
            height: '31px',
            width: '600px',
            verticalAlign: 'top',
            border: '1px solid black'
          }}
        >
          <tbody>
            <tr
              style={{
                border: '1px solid black'
              }}>
              <td style={{ width: '126px', border: '1px solid black', paddingLeft: '10px' }}>
                <Body>
                  FOR GENERAL INQUIRIES:
                  <br />
                  <a style={{ textDecoration: 'underline', color: '#48D597', cursor: 'pointer' }} href="mailto:Legal@Accolade.com">Legal@Accolade.com</a>
                </Body>
              </td>
              <td style={{ width: '334px', paddingLeft: '10px' }}>
                <Body>
                  MAIL:
                  <br />
                  LEGAL DEPARTMENT
                  <br />
                  ACCOLADE, INC.
                  <br />
                  660 WEST GERMANTOWN PIKE, SUITE 500
                  <br />
                  PLYMOUTH MEETING, PA. 19462
                </Body>
              </td>
            </tr>
            <tr
              style={{
                border: '1px solid black'
              }}>
              <td style={{ width: '126px', border: '1px solid black', paddingLeft: '10px' }}>
                <Body>
                  FOR PRIVACY INQUIRIES:
                  <br />
                  <a style={{ textDecoration: 'underline', color: '#48D597', cursor: 'pointer' }} href="mailto:Privacy@Accolade.com">Privacy@Accolade.com</a>
                </Body>
              </td>
              <td style={{ width: '334px', paddingLeft: '10px' }}>
                <Body>
                  MAIL:
                  <br />
                  PRIVACY OFFICER
                  <br />
                  ACCOLADE, INC.
                  <br />
                  660 WEST GERMANTOWN PIKE, SUITE 500
                  <br />
                  PLYMOUTH MEETING, PA. 19462
                </Body>
              </td>
            </tr>
            <tr>
              <td style={{ width: '126px', border: '1px solid black', paddingLeft: '10px' }}>
                <Body>
                  FOR SECURITY CONCERNS:
                  <br />
                  <a style={{ textDecoration: 'underline', color: '#48D597', cursor: 'pointer' }} href="mailto:Security@Accolade.com">
                    Security@Accolade.com
                  </a>
                </Body>
              </td>
              <td style={{ width: '334px', paddingLeft: '10px' }}>
                <Body>
                  MAIL:
                  <br />
                  CHIEF INFORMATION SECURITY OFFICER
                  <br />
                  ACCOLADE, INC.
                  <br />
                  1201 THIRD AVE, SUITE 1700
                  <br />
                  SEATTLE, WA 98101
                </Body>
              </td>
            </tr>
          </tbody>
        </table>
        <TitleMedium textStyle={{ color: '#003DA6' }}>YOUR ACCEPTANCE</TitleMedium>
        <Body>
          By using the Accolade Digital Tools, you are expressly agreeing to be bound by these Terms. You understand that this will mean that you have read and understood these Terms and the Authorizations and will be bound thereby. You may revoke your acceptance to the terms by ceasing use of the Accolade Digital Tools.
        </Body>
        <Body>
          You may also separately revoke your Authorization granted to Accolade to use your information to contact you about other products and services by updating your profile to indicate that you no longer authorize such use. You understand that your PHI can continue to be used for purposes permitted by the privacy rules, regardless of such revocation.
        </Body>
        <Body>
          Any revocation will not affect actions taken by Accolade in reliance of your acceptance and authorization prior to such revocation.
        </Body>
      </div>
    </>
  </Layout >
)
